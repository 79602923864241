import { Color } from 'utils/theme';

export enum Quiz {
  Main = 'main-dog',
  BreedsList = 'dogs-breed-list',
}

export interface QuizAnswer {
  [key: string]: string | boolean | number;
}

export interface QuestionOption {
  label: string;
  value: string;
  score: string;
  maxHeight?: string;
  isDogWordDynamic?: boolean;
  color?: Color;
  image?: string;
  custom: {
    icon: string;
    deselectAll?: boolean;
    horizontal?: boolean;
    disabled?: string;
  };
}

interface Image {
  desktopImg?: string;
  mobileImg?: string;
  alt?: string;
}

export type Question = {
  key: string;
  label: string;
  title?: string;
  desktopImg?: string;
  mobileImg?: string;
  image?: string;
  alt: string;
  mdpiImage?: Image;
  description?: string;
  descriptionIsMaxWidth?: boolean;
  paragraphs?: string[];
  navLinks?: { url: string; title: string }[];
  socialLinks?: { svgId: string; url: string }[];
  disclaimerParagraphs?: string[];
  disclaimerParagraphsPR?: string[];
  options?: QuestionOption[];
  accept?: Array<string>;
  buttons?: string[];
} & Record<string, string>;
