import { Cloudinary } from '@cloudinary/url-gen';
import { config } from 'config';

const environment = process.env.NODE_ENV;

export const cloudinary = new Cloudinary({
  cloud: {
    apiKey:
      environment === 'development'
        ? process.env.GATSBY_CLOUDINARY_API_KEY
        : config.CLOUDINARY_API_KEY,
    apiSecret:
      environment === 'development'
        ? process.env.GATSBY_CLOUDINARY_API_SECRET
        : config.CLOUDINARY_API_SECRET,
    cloudName:
      environment === 'development'
        ? process.env.GATSBY_CLOUDINARY_CLOUD_NAME
        : config.CLOUDINARY_CLOUD_NAME,
  },
});
