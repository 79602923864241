import React, { FC } from 'react';
import styled from 'styled-components';

interface LoopingBannerProps {
  bannerTexts: string[];
  isNotSticky?: boolean;
  savings?: string;
  primaryTextColor?: string;
  secondaryTextColor?: string;
  primaryGradient?: string;
  backgroundColor?: string;
}

const LoopingBanner: FC<LoopingBannerProps> = ({
  bannerTexts,
  isNotSticky,
  savings,
  primaryTextColor,
  secondaryTextColor,
  primaryGradient,
  backgroundColor,
}) => {
  const bookSavings = location.pathname.includes('checkout-special')
    ? '65'
    : '50';

  return (
    <Wrapper isNotSticky={isNotSticky} backgroundColor={backgroundColor}>
      <Container>
        {Array(50)
          .fill(null)
          .map((_, index) => (
            <BannerText
              key={index}
              primaryTextColor={primaryTextColor}
              secondaryTextColor={secondaryTextColor}
              primaryGradient={primaryGradient}
              dangerouslySetInnerHTML={{
                __html: bannerTexts[index % bannerTexts.length].replace(
                  '${percent}',
                  savings ? savings : bookSavings,
                ),
              }}
            />
          ))}
      </Container>
    </Wrapper>
  );
};

export default LoopingBanner;

const Wrapper = styled.div<{ isNotSticky?: boolean; backgroundColor?: string }>`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: ${({ isNotSticky }) => (isNotSticky ? 'relative' : 'sticky')};
  top: 0;
  height: 2.625rem;
  display: flex;
  padding: 0.75rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  z-index: 9;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#ef71a0'};
`;

const Container = styled.div`
  display: inline-block;
  position: absolute;
  left: 0;
  will-change: transform;
  animation: scroll 90s linear infinite;
  padding-right: 100%;
  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }

  @keyframes scroll {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
`;

const BannerText = styled.span<{
  primaryTextColor?: string;
  secondaryTextColor?: string;
  primaryGradient?: string;
}>`
  display: inline-flex;
  align-items: center;
  padding: 0 1rem;
  line-height: 1.1;

  color: ${({ secondaryTextColor }) =>
    secondaryTextColor ? secondaryTextColor : '#000'};

  span {
    color: ${({ primaryTextColor }) =>
      primaryTextColor ? primaryTextColor : '#FFFFFF'};
    ${({ primaryGradient }) =>
      primaryGradient &&
      `
    background-image: ${primaryGradient};
    color: transparent;
    background-clip: text;
  `};
  }
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.017rem;
`;
