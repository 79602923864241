import React, { ComponentPropsWithoutRef } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import { mobile, useQuery } from 'styles/breakpoints';
import CheckSvg from 'assets/icons/check.svg';
import ButtonArrow from '../assets/icons/formkit_right.svg';
import CheckBox from 'components/CheckBox';

interface QuizAnswerButtonProps extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  active?: boolean;
  children: string;
  ImageComponent?: ReactNode;
  maxSelected?: boolean;
  hideCheckIcon?: boolean;
}
// border: ${({ active }) => (active ? 0.09 : 0.06)}rem solid
// ${({ theme, active }) => active ? 'light100' : '#21155E'};
const Container = styled(ButtonContainer)<{
  active?: boolean;
  maxSelected?: boolean;
  $isWithCheckIcon?: boolean;
}>`
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  // border: ${({ active }) => (active ? 0.09 : 0.06)}rem solid
  //   ${({ theme, active }) =>
    active ? 'transparent' : theme.colors.light100};
  border: none;

  cursor: ${({ maxSelected }) => (maxSelected ? 'auto' : 'cursor')};

  border-radius: 8px;
  // box-shadow: ${({ theme }) => theme.shadows.quizButtonShadow};
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 12px 16px;
  // ${({ $isWithCheckIcon }) => ($isWithCheckIcon ? '2rem' : '10px')};

  background-color: ${({ theme, active }) =>
    active ? theme.colors.newGreen : '#F6F4EF'};

  opacity: ${({ maxSelected }) => (maxSelected ? '0.5' : '1')};
  height: 56px;
  @media ${mobile} {
    width: unset;
  }
`;

const RightArrow = styled(ButtonArrow)<{ active?: boolean }>`
  & path {
    fill: ${({ theme, active }) => (active ? '#FFF' : 'initial')};
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 80px;
  max-height: 64px;
`;

const ButtonText = styled(Text)<{
  $active?: boolean;
}>`
  font-weight: 400;
  text-align: left;
`;

const CheckIcon = styled(CheckSvg)`
  // position: absolute;
  // right: 12px;
  // top: 50%;
  // transform: translateY(-50%);
  width: 12px;
  height: 12px;
  fill: ${({ theme }) => theme.colors.light0};
`;

const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media ${mobile} {
    gap: 8px;
  }
`;

const QuizAnswerButton: FC<QuizAnswerButtonProps> = ({
  onClick,
  active,
  children,
  ImageComponent,
  maxSelected,
  hideCheckIcon = false,
  ...props
}) => {
  const { isTablet } = useQuery();
  return (
    <Container
      onClick={onClick}
      active={active}
      $isWithCheckIcon={active && !hideCheckIcon}
      maxSelected={maxSelected}
      as="button"
      {...props}
    >
      <CheckContainer>
        {!hideCheckIcon && <CheckBox checked={active} quizVariant />}
        {/* {active && !hideCheckIcon && <CheckIcon />} */}
        {ImageComponent && <ImageContainer>{ImageComponent}</ImageContainer>}
        <ButtonText
          color={active ? 'light0' : 'dark100'}
          type={isTablet ? 'bodyM500' : 'body500'}
          $active={active}
        >
          {children}
        </ButtonText>
      </CheckContainer>
      {hideCheckIcon && (
        <RightArrow
          active={active}
          width={isTablet ? 8 : 12}
          height={isTablet ? 16 : 28}
        />
      )}
    </Container>
  );
};

export default React.memo(QuizAnswerButton);
