import { createReducer, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';

import { setBreedsList } from './actions';
import { IBreedsState } from './types';

const breedList = createReducer<IBreedsState['breedsList']>(
  { list: [] },
  reduce(setBreedsList, set),
);
export default breedList;
