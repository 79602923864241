import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  description?: string;
  name?: string;
}

export const Seo: React.FC<Props> = ({
  title = 'Dog training book | Raising Dog',
  description = 'Raising Dog is a revolutionary way to raise your perfect dog with love. Overcome behavioral challenges, teach tricks, and build a lifetime bond with your best friend.',
  name = 'Raising Dog: Personalized Dog Training Book',
  children,
}) => (
  <Helmet>
    {title ? <title>{title}</title> : null}
    <meta itemProp="name" content={name} />
    <meta id="description" name="description" content={description} />
    <link rel="canonical" href={location.toString()} />

    {/* FB tags */}
    <meta property="og:url" content="https://raising.dog" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={name} />
    <meta property="og:description" content={description} />
    {children}
  </Helmet>
);
