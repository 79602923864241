import Cookies from 'js-cookie';

export const getAllCookies = (): { [key: string]: string } => {
  const cookies = document.cookie.split('; ');
  const result: { [key: string]: string } = {};

  cookies.forEach(cookie => {
    const [key, value] = cookie.split('=');

    result[key] = value;
  });

  return result;
};

export const getCookiesByKeys = (keys: string[]): { [key: string]: string } => {
  const cookies = getAllCookies();
  const result: { [key: string]: string } = {};

  keys.forEach(key => {
    if (cookies[key]) {
      result[key] = cookies[key];
    }
  });

  return result;
};

export const getCookiesEmail = () => {
  const userEmail = Cookies.get('rdEm');
  const userCode = Cookies.get('rdId');
  return { email: userEmail, code: userCode } || null;
};

export const checkIfCookiesUsed = () => {
  const userCode = Cookies.get('modalUsed');
  return userCode === 'true' ? true : false;
};

export const removeCookiesEmail = () => {
  Cookies.remove('rdEm', { path: `${window.location.hostname}` });
  Cookies.remove('rdId', { path: `${window.location.hostname}` });
  Cookies.remove('modalUsed', { path: `${window.location.hostname}` });

  return true;
};
