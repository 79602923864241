import { config } from 'config';
import { Product } from 'types/product';

import {
  GACartItem,
  GAEvent,
  GAItemSelected,
  GAMainPurchaseInfo,
  GAPaymentInfo,
  GAPurchaseInfo,
  GAQuestionAnswer,
  GAShippingInfo,
} from '../types/analyticsTypes';
import { getLocalisedProduct } from './localization';

export class AnalyticsClass {
  private readonly analyticsEnabled: boolean = false;
  private firedEvents: string[] = [];

  constructor(isEnabled: boolean) {
    this.analyticsEnabled = isEnabled;
  }

  clearEcommerce = () => {
    window.dataLayer?.push({
      ecommerce: null,
    });
  };

  clearItems = () => {
    window.dataLayer?.push({
      items: null,
    });
  };

  clearQuizData = () => {
    window.dataLayer?.push({
      question_number: undefined,
      question: undefined,
      answer: undefined,
    });
  };

  pushToDataLayer = (
    event: GAEvent,
    params?: { [key: string]: unknown },
    eventId?: string,
    external_id?: string,
  ) => {
    if (this.analyticsEnabled) {
      // Sometimes datalayer is still not intialized when we want to push an event
      if (!window.dataLayer) {
        setTimeout(() => {
          this.pushToDataLayer(event, params);
        }, 0);
        return;
      }

      try {
        window.dataLayer?.push({
          event,
          eventID: eventId,
          external_id: external_id,
          ...params,
        });
        this.firedEvents.push(event);
      } catch (e) {
        console.error(e);
      }
    }
  };

  pushToDataLayerPurchase = (
    event: GAEvent,
    purchase_type: string,
    params?: { [key: string]: unknown },
    eventId?: string,
    external_id?: string,
  ) => {
    if (this.analyticsEnabled) {
      // Sometimes datalayer is still not intialized when we want to push an event
      if (!window.dataLayer) {
        setTimeout(() => {
          this.pushToDataLayer(event, params);
        }, 0);
        return;
      }

      try {
        window.dataLayer?.push({
          event,
          eventID: eventId,
          external_id: external_id,
          purchase_type: purchase_type,
          ...params,
        });
        this.firedEvents.push(event);
      } catch (e) {
        console.error(e);
      }
    }
  };

  trackStartQuiz = () => {
    this.pushToDataLayer(GAEvent.QuizStarted);
  };

  trackQuizQuestionAnswered = (
    gaQuestionAnswer: GAQuestionAnswer,
    eventID: string,
  ) => {
    this.pushToDataLayer(
      GAEvent.QuizQuestionAnswered,
      { ...gaQuestionAnswer },
      eventID,
    );
  };

  trackArticleLinkClicked = () => {
    this.pushToDataLayer(GAEvent.ArticleLinkClicked);
  };

  logEvent = (data: any, eventID: string, clientCode?: string | null) => {
    this.pushToDataLayer(
      GAEvent.LogEvent,
      { ...data },
      eventID,
      clientCode || '',
    );
  };

  virtualPageView = (
    data: any,
    eventID: string,
    clientCode?: string | null,
  ) => {
    this.pushToDataLayer(
      GAEvent.VirtualPageView,
      { ...data },
      eventID,
      clientCode || '',
    );
  };

  trackPaymentSubmit = (
    method: string,
    orderName: string,
    eventID: string,
  ): void => {
    this.pushToDataLayer(
      GAEvent.PaymentInitialized,
      {
        event: 'payment_initialized',
        payment_method: method,
        subscription_plan: orderName,
      },
      eventID,
    );
  };

  trackUserCodeGenerated = (
    userCode: string,
    email: string,
    country_code: string,
    state: string,
    eventId: string,
  ) => {
    this.pushToDataLayer(
      GAEvent.UserCodeGenerated,
      {
        user_id: userCode,
        email: email,
        country_code: country_code,
        state: state,
      },
      eventId,
      userCode,
    );
  };

  trackCheckoutInitiatedTikTok = (
    userCode: string,
    email: string,
    selectedProducts: Product[],
    eventId: string,
  ) => {
    const gaItem = selectedProducts.map(item => getLocalisedProduct(item));

    let aggregatePrice = 0;
    this.clearEcommerce();

    gaItem.forEach(
      item => (aggregatePrice = aggregatePrice + Number(item?.finalPrice)),
    );

    this.pushToDataLayer(
      GAEvent.InitiateCheckoutTikTok,
      {
        user_id: userCode,
        email: email,
        ecommerce: {
          currency: gaItem[0]?.currencyId,
          value: aggregatePrice,
          items: gaItem.map(item => ({
            item_id: item?.key,
            item_name: item?.name,
            item_category: 'Subscription',
            currency: item?.currencyId,
            discount: Number(item?.discount),
            price: String(item?.finalPrice || '0'),
            quantity: 1,
          })),
        },
      },
      eventId,
      userCode,
    );
  };

  trackCheckoutInitiated = (
    userCode: string,
    email: string,
    selectedProducts: Product[],
    eventId: string,
  ) => {
    const gaItem = selectedProducts.map(item => getLocalisedProduct(item));

    let aggregatePrice = 0;
    this.clearEcommerce();

    gaItem.forEach(
      item => (aggregatePrice = aggregatePrice + Number(item?.finalPrice)),
    );

    this.pushToDataLayer(
      GAEvent.InitiateCheckout,
      {
        user_id: userCode,
        email: email,
        ecommerce: {
          currency: gaItem[0]?.currency,
          value: aggregatePrice,
          items: gaItem.map(item => ({
            item_id: item?.key,
            item_name: item?.name,
            item_category: 'Subscription',
            currency: item?.currency,
            discount: Number(item?.discount),
            price: String(item?.finalPrice || '0'),
            quantity: 1,
          })),
        },
      },
      eventId,
      userCode,
    );
  };

  trackPaymentInfoAdded = (paymentInfo: GAPaymentInfo) => {
    this.pushToDataLayer(GAEvent.PaymentInfoAdded, {
      ecommerce: { ...paymentInfo },
    });
  };

  trackShippingInfoAdded = (shippingInfo: GAShippingInfo) => {
    this.pushToDataLayer(GAEvent.ShippingInfoProvided, {
      ecommerce: { ...shippingInfo },
    });
  };

  trackPurchaseInitiated = (
    purchaseInfo: GAPurchaseInfo,
    purchase_type: string,
    eventID: string,
    external_id: string,
  ) => {
    this.pushToDataLayerPurchase(
      GAEvent.PurchaseEvent,
      purchase_type,
      {
        ecommerce: { ...purchaseInfo, purchase_type },
      },
      eventID,
      external_id,
    );
  };

  trackMainPurchaseInitiated = (
    purchaseInfo: GAMainPurchaseInfo,
    eventID: string,
    external_id: string,
  ) => {
    this.pushToDataLayer(
      GAEvent.PurchaseMainEvent,
      { ...purchaseInfo },
      eventID,
      external_id,
    );
  };

  trackPurchaseUpsellInitiated = (
    purchaseInfo: GAPurchaseInfo,
    eventID: string,
    external_id: string,
  ) => {
    this.pushToDataLayer(
      GAEvent.UpsellPurchaseEvent,
      { ...purchaseInfo },
      eventID,
      external_id,
    );
  };

  trackPurchaseUpsellLifetime = (purchaseInfo: GAPurchaseInfo) => {
    this.pushToDataLayer(GAEvent.PurchaseUpsellLifetime, { ...purchaseInfo });
  };

  trackPurchaseDownsellInitiated = (purchaseInfo: GAPurchaseInfo) => {
    this.pushToDataLayer(GAEvent.DownsellPurchaseEvent, { ...purchaseInfo });
  };

  trackItemSelected = (itemSelectedEventData: GAItemSelected) => {
    this.pushToDataLayer(GAEvent.ItemSelected, {
      ecommerce: { ...itemSelectedEventData },
    });
  };

  trackAddedToCart = (cartItemInfo: GACartItem) => {
    this.pushToDataLayer(GAEvent.AddToCart, {
      ecommerce: { ...cartItemInfo },
    });
  };

  trackCTAButton = (url: string, eventID: string): void => {
    this.pushToDataLayer(
      GAEvent.trackCTAButton,
      {
        url: url,
      },
      eventID,
    );
  };

  trackRegistration = (code: string, eventID: string): void => {
    this.pushToDataLayer(GAEvent.SuccessfulRegistration, {
      clientCode: code,
    }),
      eventID;
  };
}

export const Analytics = new AnalyticsClass(config.TRACKING_ENABLED);
