import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'components';
import { tablet } from 'styles/breakpoints';

const Button = styled(PrimaryButton)`
  max-width: 27rem;
  width: 100%;
  margin: 0 auto;
  @media ${tablet} {
    width: 100%;
    margin: 0;
  }
`;

const ContinueButton = (props: ComponentProps<typeof PrimaryButton>) => (
  <Button {...props}>{props?.title ?? 'Continue'}</Button>
);

export default ContinueButton;
